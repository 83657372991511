import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/home";
import AboutUs from "./pages/aboutUs";
import OurServices from "./pages/our-services";
import HealthSafety from "./pages/health&safety";
import Blog from "./pages/blog";
import ContactUs from "../src/pages/contactUs/index"
import OurProjects from "./pages/ourProjects";

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <AboutUs />,
    },
    {
      path: "/services",
      element: <OurServices />,
    },
    {
      path: "/health-safety",
      element: <HealthSafety />,
    },
    {
      path: "/our-projects",
      element: <OurProjects/>,
    },
    {
      path: "/contact",
      element: <ContactUs />,
    },
  ]);
  return (
    <RouterProvider router={router} />
  );
}
